import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function LeadsResponses() {
  const Navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <div className="dashboard-cards">
            <Row>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>New Leads</h3>
                    <p className="total-users">Today</p>
                    <img
                      src={require("../Assets/Images/new-leads.png")}
                      className="graph"
                    />
                    <h2>
                    153
                    </h2>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>Responses</h3>
                    <p className="total-users">Today</p>
                    <img
                      src={require("../Assets/Images/responses.png")}
                      className="graph"
                    />
                    <h2>367</h2>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <img
                      src={require("../Assets/Images/ratio.png")}
                      className="user"
                    />
                    <h3>Ratio</h3>
                    <p className="total-users">Last week</p>
                    <span>3 / 1</span>
                    <div className="percent-txt">
                      <p>-8%</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <img
                      src={require("../Assets/Images/ratio.png")}
                      className="user"
                    />
                    <h3>Top Location</h3>
                    <p className="total-users">Last week</p>
                    <span>Manchester</span>
                    <div className="percent-txt">
                      <p className="text-success">350 Leads</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>Most Popular Day</h3>
                    <p className="total-users">Weekly Report</p>
                    <div className="popular-day-txt">
                      <div>
                        <h2>Friday</h2>
                        <span>+15.2%</span>
                      </div>
                      <img src={require("../Assets/Images/popular-day.png")} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="table-data-title border-none">
              <div className="category-field">
                <Row>
                  <Col lg={4}>
                    <div className="input-search">
                      <Form>
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            className="w-100"
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="input-select">
                      <Form.Select aria-label="Default select example">
                        <option> Date Range </option>
                        <option value="1">1 Month</option>
                        <option value="2">6 Month</option>
                        <option value="3">1 year</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="input-select">
                      <Form.Select aria-label="Default select example">
                        <option> Category</option>
                        <option value="1">1 Month</option>
                        <option value="2">6 Month</option>
                        <option value="3">1 year</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        Date
                      </div>
                    </th>
                    <th>User</th>
                    <th>Lead Overview</th>
                    <th>Location</th>
                    <th>Responses</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div
                            className="user-id cursor-pointer"
                            onClick={() => Navigate("/lead-details")}
                          >
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>01/12/2024</p>
                            <span>08:35am</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> Simon Jones</div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Call Out</p>
                            <span>Emergency Services</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="debit">Manchester</div>
                    </td>
                    <td>
                      <div className="debit">0/5</div>
                    </td>

                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/edit-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <p className="mb-1"></p>
        </div>
      </Container>
    </Layout>
  );
}
