import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/SparkPro-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/dashboard"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/dashboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.0003 8.71001L13.6673 4.56201C12.7045 3.81299 11.3561 3.81299 10.3933 4.56201L5.05927 8.71001C4.40968 9.21518 4.02989 9.99211 4.03027 10.815V18.015C4.03027 19.1196 4.9257 20.015 6.03027 20.015H18.0303C19.1348 20.015 20.0303 19.1196 20.0303 18.015V10.815C20.0303 9.99201 19.6503 9.21501 19.0003 8.71001"
                    stroke="#2F2B3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 14.9999C13.79 16.3329 10.208 16.3329 8 14.9999"
                    stroke="#2F2B3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Dashboard</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>Members</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Users</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/electricians"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/electricians"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M15 15.0001V15.0126"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.8388 6.16127C21.8861 4.20864 16.3459 6.58301 11.4644 11.4646C6.58283 16.3461 4.20847 21.8863 6.16109 23.8389C8.11371 25.7916 13.6539 23.4172 18.5355 18.5356C23.417 13.6541 25.7914 8.11389 23.8388 6.16127"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.16102 6.16124C4.2084 8.11387 6.58277 13.6541 11.4643 18.5356C16.3459 23.4172 21.8861 25.7915 23.8387 23.8389C25.7913 21.8863 23.4169 16.3461 18.5354 11.4645C13.6538 6.58299 8.11364 4.20862 6.16102 6.16124"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Electricians</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user-verification"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user-verification"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M13.9998 14V17.2308"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="13.9998"
                    cy="12.9231"
                    r="1.07692"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.0002 3.23071C16.7992 5.70354 20.4523 6.99105 24.1857 6.82046C25.2838 10.5508 24.8201 14.5665 22.9004 17.9493C20.9807 21.3321 17.7692 23.793 14.0002 24.7692C10.2311 23.793 7.01968 21.3321 5.1 17.9493C3.18032 14.5665 2.71656 10.5508 3.81472 6.82046C7.54803 6.99105 11.2012 5.70354 14.0002 3.23071"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Verification Requests</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>Activity</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/leads-responses"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/leads-responses"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <rect
                    x="4.6665"
                    y="4.66663"
                    width="7"
                    height="5.83333"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="4.6665"
                    y="15.1666"
                    width="7"
                    height="8.16667"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="16.3335"
                    y="4.66663"
                    width="7"
                    height="18.6667"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Leads & Responses</p>
              </Link>
            </div>
            <div className="side-pages">
              <p>Accounts</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/transactions"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/transactions"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <rect
                    x="3.25"
                    y="13"
                    width="6.5"
                    height="8.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="9.75"
                    y="8.66663"
                    width="6.5"
                    height="13"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="16.25"
                    y="4.33337"
                    width="6.5"
                    height="17.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.3335 21.6667H19.5002"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Transactions</p>
              </Link>
            </div>
            <div className="side-pages">
              <p>Communication</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/electrician-mailing-list"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/electrician-mailing-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <rect
                    x="3.25"
                    y="5.41663"
                    width="19.5"
                    height="15.1667"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.25 7.58337L13 14.0834L22.75 7.58337"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Electrician Mailing List</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user-mailing-list"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user-mailing-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <rect
                    x="3.25"
                    y="5.41663"
                    width="19.5"
                    height="15.1667"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.25 7.58337L13 14.0834L22.75 7.58337"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>User Mailing List</p>
              </Link>
            </div>
            <div className="side-pages">
              <p>Administration</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/manage-administrators"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/manage-administrators"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Manage Administrators</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
