import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import Dashboard from "./Pages/Dashboard";
import Electricians from "./Pages/Electricians";
import LeadsResponses from "./Pages/LeadsResponses";
import Transactions from "./Pages/Transactions";
import ElectricianMailingList from "./Pages/ElectricianMailingList";
import UserMailingList from "./Pages/UserMailingList";
import ManageAdministrators from "./Pages/ManageAdministrators";
import UserProfile from "./Pages/UserProfile";
import ElectricianProfile from "./Pages/ElectricianProfile";
import AdminDetails from "./Pages/AdminDetails";
import LeadDetail from "./Pages/LeadDetail";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/error" element={<Error />}></Route>
          <Route
            path="/under-maintenance"
            element={<UnderMaintenance />}
          ></Route>
          <Route path="/otp-varification" element={<OtpVarification />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/user" element={<User />}></Route>
          <Route path="/electricians" element={<Electricians />}></Route>

          <Route path="/my-profile" element={<MyProfile />}></Route>
          <Route path="/user-profile" element={<UserProfile />}></Route>
          <Route
            path="/user-verification"
            element={<UserVerification />}
          ></Route>
          <Route path="/leads-responses" element={<LeadsResponses />}></Route>
          <Route path="/transactions" element={<Transactions />}></Route>
          <Route
            path="/electrician-mailing-list"
            element={<ElectricianMailingList />}
          ></Route>
          <Route
            path="/user-mailing-list"
            element={<UserMailingList />}
          ></Route>
          <Route
            path="/manage-administrators"
            element={<ManageAdministrators />}
          ></Route>
          <Route
            path="/electrician-profile"
            element={<ElectricianProfile />}
          ></Route>
          <Route path="/admin-details" element={<AdminDetails />}></Route>
          <Route path="/lead-details" element={<LeadDetail />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
