import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

export default function AdminDetails() {
  return (
    <Layout>
      <Container fluid>
        <div className="cmn-detail-head-box mt-4">
          <div className="cmn-detail-top"></div>
          <div className="cmn-detail-bottom">
            <img src={require("../Assets/Images/my-profile.svg").default} />
            <div className="cmn-detail-data">
              <div className="">
                <h2>Ryan Jameson</h2>
                <ul>
                  <li>Administrator</li>
                </ul>
              </div>
              <div className="comn-btn-set">
                <button>Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="mt-4">
        <Container fluid>
          <div className="my-profile-page mt-4">
            <div className="profile-top-box">
              <h5 className="mb-4">Basics</h5>
              <div className="photo-upload">
                <div className="profile-picture">
                  <img
                    src={require("../Assets/Images/my-profile.svg").default}
                  />
                </div>
                <div className="upload-box">
                  <div className="btn-up">
                    <input
                      type="file"
                      accept="image/*"
                      id="uploadPhoto"
                      class="file-input"
                    />
                    <label for="uploadPhoto" class="custom-file-upload">
                      Upload New Logo
                    </label>
                    <div className="reset-box">
                      <p>Delete</p>
                    </div>
                  </div>
                  <p className="image-disp">Allowed JPG or PNG</p>
                </div>
              </div>

              <div className="profile-disp mt-5">
                <div className="form-set input-profile">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="type" placeholder="Ryan Jameson" />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="ryan@sparkpro.app"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="verification-status">
                <Form.Select aria-label="Default select example">
                  <option>Full Access</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </div>

            <div className="profile-top-box mt-4">
              <div className="heading-profile">
                <h2 className="mb-4">Account Actions</h2>
                <div className="d-flex flex-direction-column gap-3">
                  <button className="cmmn-white-btn">Reset Password</button>
                  <button className="cmmn-white-btn">Suspend Account</button>
                </div>
              </div>
            </div>
            <div className="delete-account mt-4">
              <div className="delete-heading-text  ">
                <h1>Delete Account</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to delete this account?</p>
                <span>
                  Once you delete this account, there is no going back. Please
                  be certain.
                </span>
              </div>
              <div className="delete-check-box">
                <div className="remember-check check-box-over">
                  <input type="checkbox" class="red" id="filled-in-box" />
                  <Form.Label>I confirm my account deactivation</Form.Label>
                </div>
              </div>
              <div className="btn-delete-account">
                <button>Deactivate Account</button>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <p class="mb-1"></p>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
