import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Dashboard() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <div className="dashboard-cards">
            <Row>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>New Leads</h3>
                    <p className="total-users">Last week</p>
                    <img
                      src={require("../Assets/Images/new-leads.png")}
                      className="graph"
                    />
                    <h2>
                      1.2k <span>+12.6%</span>
                    </h2>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>Responses</h3>
                    <p className="total-users">Last week</p>
                    <img
                      src={require("../Assets/Images/responses.png")}
                      className="graph"
                    />
                    <h2>
                      3.6k <span>+12.6%</span>
                    </h2>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <img
                      src={require("../Assets/Images/ratio.png")}
                      className="user"
                    />
                    <h3>Ratio</h3>
                    <p className="total-users">Last week</p>
                    <span>3 / 1</span>
                    <div className="percent-txt">
                      <p>-8%</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <img
                      src={require("../Assets/Images/ratio.png")}
                      className="user"
                    />
                    <h3>Top Location</h3>
                    <p className="total-users">Last week</p>
                    <span>Manchester</span>
                    <div className="percent-txt">
                      <p className="text-success">350 Leads</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <h3>Most Popular Day</h3>
                    <p className="total-users">Weekly Report</p>
                    <div className="popular-day-txt">
                      <div>
                        <h2>Friday</h2>
                        <span>+15.2%</span>
                      </div>
                      <img src={require("../Assets/Images/popular-day.png")} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="dashboard-items">
          <div className="dashboard-cards">
            <Row>
              <Col lg={8}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <div className="d-flex justify-content-between">
                      <h3>Earning Reports</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <circle
                          cx="10.9999"
                          cy="10.9999"
                          r="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle
                          cx="10.9999"
                          cy="17.4167"
                          r="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <ellipse
                          cx="10.9999"
                          cy="4.58341"
                          rx="0.916667"
                          ry="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="total-users">Yearly Earnings Overview</p>
                  </div>
                  <ul className="earning-reports-filter">
                    <li className="active">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6C0 2.68629 2.68629 0 6 0H32C35.3137 0 38 2.68629 38 6V32C38 35.3137 35.3137 38 32 38H6C2.68629 38 0 35.3137 0 32V6Z"
                          fill="#337FE0"
                          fill-opacity="0.35"
                        />
                      </svg>

                      <p>Total</p>
                    </li>
                    <li>
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6C0 2.68629 2.68629 0 6 0H32C35.3137 0 38 2.68629 38 6V32C38 35.3137 35.3137 38 32 38H6C2.68629 38 0 35.3137 0 32V6Z"
                          fill="#2F2B3D"
                          fill-opacity="0.06"
                        />
                        <rect
                          x="10.75"
                          y="19"
                          width="5.5"
                          height="7.33333"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="16.25"
                          y="15.3333"
                          width="5.5"
                          height="11"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="21.75"
                          y="11.6667"
                          width="5.5"
                          height="14.6667"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.6667 26.3333H24.5001"
                          stroke="#2F2B3D"
                          stroke-opacity="0.7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p>Subscriptions</p>
                    </li>
                    <li>
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6C0 2.68629 2.68629 0 6 0H32C35.3137 0 38 2.68629 38 6V32C38 35.3137 35.3137 38 32 38H6C2.68629 38 0 35.3137 0 32V6Z"
                          fill="#2F2B3D"
                          fill-opacity="0.06"
                        />
                        <path
                          d="M23.3084 15.3333C22.9351 14.2742 21.9554 13.5484 20.8334 13.5H17.1667C15.648 13.5 14.4167 14.7312 14.4167 16.25C14.4167 17.7688 15.648 19 17.1667 19H20.8334C22.3522 19 23.5834 20.2312 23.5834 21.75C23.5834 23.2688 22.3522 24.5 20.8334 24.5H17.1667C16.0448 24.4516 15.065 23.7258 14.6917 22.6667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.7501 10.75C19.7501 10.3358 19.4143 10 19.0001 10C18.5859 10 18.2501 10.3358 18.2501 10.75H19.7501ZM18.2501 13.5C18.2501 13.9142 18.5859 14.25 19.0001 14.25C19.4143 14.25 19.7501 13.9142 19.7501 13.5H18.2501ZM19.7501 24.5C19.7501 24.0858 19.4143 23.75 19.0001 23.75C18.5859 23.75 18.2501 24.0858 18.2501 24.5H19.7501ZM18.2501 27.25C18.2501 27.6642 18.5859 28 19.0001 28C19.4143 28 19.7501 27.6642 19.7501 27.25H18.2501ZM18.2501 10.75V13.5H19.7501V10.75H18.2501ZM18.2501 24.5V27.25H19.7501V24.5H18.2501Z"
                          fill="#2F2B3D"
                          fill-opacity="0.7"
                        />
                      </svg>
                      <p>Top Ups</p>
                    </li>
                  </ul>
                  <img
                    src={require("../Assets/Images/earning-reports-graph.png")}
                    className="img-fluid w-100"
                  />
                </div>
              </Col>

              <Col lg={4}>
                <div className="dashboard-item">
                  <div className="left-session">
                    <div className="d-flex justify-content-between">
                      <h3> Leads</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <circle
                          cx="10.9999"
                          cy="10.9999"
                          r="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle
                          cx="10.9999"
                          cy="17.4167"
                          r="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <ellipse
                          cx="10.9999"
                          cy="4.58341"
                          rx="0.916667"
                          ry="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="total-users">Last 6 Months</p>
                  </div>
                  <img
                    src={require("../Assets/Images/Leads-graphs.png")}
                    className="img-fluid w-100 mt-4 mb-4"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
