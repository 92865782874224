import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function UserVerification() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap ">
                <Row>
                  <Col xxl={6} xl={6} lg={6}>
                    <div className="dash-inner-boxes ">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Total</p>
                          <h2>32</h2>
                        </div>
                        <div className="user-icon-se">
                          <svg
                            width="42"
                            height="42"
                            viewBox="0 0 42 42"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 6C0 2.68629 2.68629 0 6 0H36C39.3137 0 42 2.68629 42 6V36C42 39.3137 39.3137 42 36 42H6C2.68629 42 0 39.3137 0 36V6Z"
                              fill="#337FE0"
                              fill-opacity="0.16"
                            />
                            <ellipse
                              cx="17.7501"
                              cy="15.5833"
                              rx="4.33333"
                              ry="4.33333"
                              stroke="#337FE0"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.25 30.75V28.5833C11.25 26.1901 13.1901 24.25 15.5833 24.25H19.9167C22.3099 24.25 24.25 26.1901 24.25 28.5833V30.75"
                              stroke="#337FE0"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M25.3333 11.3906C27.2507 11.8816 28.5917 13.6093 28.5917 15.5885C28.5917 17.5678 27.2507 19.2955 25.3333 19.7865"
                              stroke="#337FE0"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M30.75 30.7499V28.5832C30.7387 26.6165 29.4043 24.904 27.5 24.4124"
                              stroke="#337FE0"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            {/* <div className="table-data-title">
              <h2 className="filter mb-0">Filters</h2>
            </div> */}

            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>+91</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        Electrician
                      </div>
                    </th>
                    <th>Date</th>
                    <th>Plan</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                            <span>jordan.stevenson</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user-sec.png")} />
                          <div className="user-id">
                            <p>Richard Payne</p>
                            <span>richard247</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user-next.png")}
                          />
                          <div className="user-id">
                            <p>Jennifer Summers</p>
                            <span>summers.45</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/justion.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Mr. Justin Richardson</p>
                            <span>jr.3734</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/nicholas.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Nicholas Tanner</p>
                            <span>nicholas.t</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/crystal.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Crystal Mays</p>
                            <span>mays.754</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> 01/02/2024</div>
                    </td>
                    <td>
                      <div className="debit">Standard</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <p className="mb-1"></p>
        </div>
      </Container>
    </Layout>
  );
}
