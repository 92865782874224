import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

export default function LeadDetail() {
  const Navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="mt-4">
          <button
            className="cmmn-white-btn"
            onClick={() => Navigate("/leads-responses")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="#2F2B3D"
                stroke-opacity="0.9"
                stroke-width="2"
              />
            </svg>{" "}
            Back
          </button>
        </div>
      </Container>
      <div className="mt-4">
        <Container fluid>
          <div className="my-profile-page mt-4">
            <div className="profile-top-box">
              <h5 className="mb-4">Customer</h5>
              <div className="first-user">
                <div className="user-profile">
                  <img src={require("../Assets/Images/user.svg").default} />
                  <div
                    className="user-id cursor-pointer"
                    onClick={() => Navigate("/admin-details")}
                  >
                    <p>Jordan Stevenson</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-top-box  mt-4">
              <h5 className="mb-2">Lead Details</h5>
              <hr />
              <div className="lead-details-text">
                <h4>Job Type</h4>
                <p>Dental Surgery</p>
                <h4>What kind of requirement is this?</h4>
                <p>Commercial Electrician</p>
                <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </h4>
                <p>Lorem ipsum</p>{" "}
                <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </h4>
                <p>Lorem ipsum</p>
                <h4>Personal Project or Business?</h4>
                <p>Business</p>
                <h4>Estimated budget</h4>
                <p>£10,000 - £20,000</p>
                <h4>Have you had any quotes before?</h4>
                <p>Yes - £12,000</p>
                <h4>Do you have an estimated start date?</h4>
                <p>Within a month</p>
                <h4>How likely are you to make a decision</h4>
                <p>I'm likely to hire someone</p>
                <h4>A brief description of your requirements</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  pellentesque iaculis sagittis. Duis id leo dapibus, rhoncus
                  nibh quis, elementum magna. Ut ac tellus dui. Aenean non
                  tincidunt ante, posuere cursus sem. Vivamus ut nisl nibh. Nunc
                  vitae erat at mi sagittis suscipit non eu quam. Vestibulum
                  aliquet enim sed interdum scelerisque.
                </p>
              </div>
            </div>

            <div className="profile-top-box mt-4">
              <div className="heading-profile">
                <h2 className="mb-2">Responses</h2>
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Electrician</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="cont-num"> 01/12/2024</div>
                        </td>

                        <td>
                          <div className="debit">08:45</div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  require("../Assets/Images/user.svg").default
                                }
                              />
                              <div
                                className="user-id cursor-pointer"
                                onClick={() => Navigate("/admin-details")}
                              >
                                <p>Jordan Stevenson</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="cont-num"> 01/12/2024</div>
                        </td>

                        <td>
                          <div className="debit">08:45</div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  require("../Assets/Images/user.svg").default
                                }
                              />
                              <div
                                className="user-id cursor-pointer"
                                onClick={() => Navigate("/admin-details")}
                              >
                                <p>Jordan Stevenson</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="cont-num"> 01/12/2024</div>
                        </td>

                        <td>
                          <div className="debit">08:45</div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  require("../Assets/Images/user.svg").default
                                }
                              />
                              <div
                                className="user-id cursor-pointer"
                                onClick={() => Navigate("/admin-details")}
                              >
                                <p>Jordan Stevenson</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="pagination-section">
                    <div className="showing-user">
                      <p>Showing 1 to 10 of 50 entries</p>
                    </div>
                    <div className="pagination-block">
                      <Pagination>
                        <Pagination.First />
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="delete-account mt-4">
              <div className="delete-heading-text  ">
                <h1>Delete Lead</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to delete this account?</p>
                <span>
                  Once you delete this account, there is no going back. Please
                  be certain.
                </span>
              </div>
              <div className="delete-check-box">
                <div className="remember-check check-box-over">
                  <input type="checkbox" class="red" id="filled-in-box" />
                  <Form.Label>I confirm my account deactivation</Form.Label>
                </div>
              </div>
              <div className="btn-delete-account">
                <button>Deactivate Account</button>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <p class="mb-1"></p>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
