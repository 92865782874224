import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

export default function UserProfile() {
  return (
    <Layout>
      <Container fluid>
        <div className="cmn-detail-head-box mt-4">
          <div className="cmn-detail-top"></div>
          <div className="cmn-detail-bottom">
            <img src={require("../Assets/Images/my-profile.svg").default} />
            <div className="cmn-detail-data">
              <div className="">
                <h2>John Doe</h2>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="11"
                        r="3"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.657 16.657L13.414 20.9C12.6331 21.68 11.3679 21.68 10.587 20.9L6.343 16.657C3.21892 13.5327 3.21901 8.46747 6.34319 5.34334C9.46738 2.21921 14.5326 2.21921 17.6568 5.34334C20.781 8.46747 20.7811 13.5327 17.657 16.657V16.657Z"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Manchester
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        x="4"
                        y="5"
                        width="16"
                        height="16"
                        rx="2"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 3V7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 3V7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 11H20"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 15H12"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15V18"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Joined November 2024
                  </li>
                </ul>
              </div>
              <div className="comn-btn-set">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <ellipse
                      cx="6.00004"
                      cy="4.66667"
                      rx="2.66667"
                      ry="2.66667"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 14V12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333C8.80609 10 10 11.1939 10 12.6667V14"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6666 7.33333L12 8.66667L14.6666 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Connected
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="mt-4">
        <Container fluid>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="comn-tabs-set">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="10.5"
                          y="3"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="3"
                          y="10.5"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="10.5"
                          y="10.5"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Leads
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M7.5 10.5C7.99373 11.004 8.66952 11.2879 9.375 11.2879C10.0805 11.2879 10.7563 11.004 11.25 10.5L14.25 7.50004C15.2855 6.46451 15.2855 4.78557 14.25 3.75004C13.2145 2.71451 11.5355 2.71451 10.5 3.75004L10.125 4.12504"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.4999 7.49998C10.0062 6.99607 9.3304 6.7121 8.62492 6.7121C7.91944 6.7121 7.24365 6.99607 6.74992 7.49998L3.74992 10.5C2.71438 11.5355 2.71438 13.2144 3.74992 14.25C4.78545 15.2855 6.46438 15.2855 7.49992 14.25L7.87492 13.875"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Favourite Electricians
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="mt-4">
                      <Row>
                        <Col lg={4}>
                          <div className="commn-card-box">
                            <div className="overview-details">
                              <h4>ABOUT</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <circle
                                      cx="12"
                                      cy="7"
                                      r="4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6 21V19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Full Name:</p>
                                  <span>John Doe</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 12L10 17L20 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Status:</p>
                                  <span>Active</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 5V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M19 5V14"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5 5.00003C6.94422 3.09431 10.0558 3.09431 12 5.00003C13.9442 6.90574 17.0558 6.90574 19 5.00003"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5 14C6.94422 12.0943 10.0558 12.0943 12 14C13.9442 15.9057 17.0558 15.9057 19 14"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>City:</p>
                                  <span>Manchester, UK</span>
                                </li>
                              </ul>
                              <h4>CONTACTS</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 20.1046 19.1046 21 18 21C9.92765 20.5094 3.49056 14.0724 3 6C3 4.89543 3.89543 4 5 4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 7C16.1046 7 17 7.89543 17 9"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 3C18.3137 3 21 5.68629 21 9"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Contact:</p>
                                  <span>+44 7477220000</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <rect
                                      x="3"
                                      y="5"
                                      width="18"
                                      height="14"
                                      rx="2"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 7L12 13L21 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Email:</p>
                                  <span>John.doe@example.com</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="commn-card-box">
                            <div className="activity-timeline-box">
                              <h2>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    x="3"
                                    y="12"
                                    width="6"
                                    height="8"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <rect
                                    x="9"
                                    y="8"
                                    width="6"
                                    height="12"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <rect
                                    x="15"
                                    y="4"
                                    width="6"
                                    height="16"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4 20H18"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Activity Timeline
                              </h2>
                              <ul>
                                <li>
                                  <div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#337FE0"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#337FE0"
                                      />
                                    </svg>
                                    <div>
                                      <h4 h4>New Lead Created</h4>
                                      <p>Call Out - Emergency Services</p>
                                    </div>
                                  </div>

                                  <span>12 min ago</span>
                                </li>
                                <li>
                                  <div>
                                    {" "}
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#28C76F"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#28C76F"
                                      />
                                    </svg>
                                    <div>
                                      <h4>Verified Phone Number</h4>
                                      <p>Verified</p>
                                    </div>
                                  </div>

                                  <span>12 min ago</span>
                                </li>
                                <li>
                                  <div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#00BAD1"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#00BAD1"
                                      />
                                    </svg>

                                    <div>
                                      <h4>Account Created</h4>
                                      <p>
                                        Registered to Spark Pro on Thursday 2nd
                                        November 2024 08:02
                                      </p>
                                    </div>
                                  </div>
                                  <span>12 min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="commn-card-box mt-4">
                            <div className="overview-details">
                              <h4>OVERVIEW</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 12L10 17L20 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Leads:</p>
                                  <span>4</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <circle
                                      cx="9"
                                      cy="7"
                                      r="4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M21 20.9999V18.9999C20.9896 17.1845 19.7578 15.6037 18 15.1499"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Responses:</p>
                                  <span>6</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="my-profile-page mt-4">
                      <div className="profile-top-box">
                        <div className="photo-upload">
                          <div className="profile-picture">
                            <img
                              src={
                                require("../Assets/Images/my-profile.svg")
                                  .default
                              }
                            />
                          </div>
                          <div className="upload-box">
                            <div className="btn-up">
                              <input
                                type="file"
                                accept="image/*"
                                id="uploadPhoto"
                                class="file-input"
                              />
                              <label
                                for="uploadPhoto"
                                class="custom-file-upload"
                              >
                                Upload New Photo
                              </label>
                              <div className="reset-box">
                                <p>Reset</p>
                              </div>
                            </div>
                            <p className="image-disp">Allowed JPG or PNG</p>
                          </div>
                        </div>

                        <div className="profile-disp mt-5">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="type"
                                placeholder="John"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="type"
                                placeholder="Doe"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" placeholder="john.doe@example.com" />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="+447477202151"
                              />
                            </Form.Group>
                          </div>
                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Address</Form.Label>
                              <Form.Control type="text" placeholder="124 City Road" />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>City</Form.Label>
                              <Form.Control type="text" placeholder=" London" />
                            </Form.Group>
                          </div>
                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Post Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="EC1V 2NX"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="btn-up mt-2">
                          <button type="submit">Save Changes</button>
                          <div className="reset-box">
                            <p>Cancel</p>
                          </div>
                        </div>
                      </div>

                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-4">Account Actions</h2>
                          <div className="d-flex flex-direction-column gap-3">
                            <button className="cmmn-white-btn">
                              Reset Password
                            </button>
                            <button className="cmmn-white-btn">
                              Suspend Account
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="delete-account mt-4">
                        <div className="delete-heading-text  ">
                          <h1>Delete Account</h1>
                        </div>
                        <div className="delete-disp">
                          <p>Are you sure you want to delete this account?</p>
                          <span>
                            Once you delete this account, there is no going
                            back. Please be certain.
                          </span>
                        </div>
                        <div className="delete-check-box">
                          <div className="remember-check check-box-over">
                            <input
                              type="checkbox"
                              class="red"
                              id="filled-in-box"
                            />
                            <Form.Label>
                              I confirm my account deactivation
                            </Form.Label>
                          </div>
                        </div>
                        <div className="btn-delete-account">
                          <button>Deactivate Account</button>
                        </div>
                      </div>
                    </div>
                    <div class="pb-5">
                      <p class="mb-1"></p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="filters mt-4">
                      <div className="inner-filter-field">
                        <div className="table-data-title border-none">
                          <div className="category-field">
                            <Row>
                              <Col lg={4}>
                                <div className="input-search">
                                  <Form>
                                    <Form.Group controlId="formBasicPassword">
                                      <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        className="w-100"
                                      />
                                    </Form.Group>
                                  </Form>
                                </div>
                              </Col>
                              <Col lg={2}>
                                <div className="input-select">
                                  <Form.Select aria-label="Default select example">
                                    <option> Date Range </option>
                                    <option value="1">1 Month</option>
                                    <option value="2">6 Month</option>
                                    <option value="3">1 year</option>
                                  </Form.Select>
                                </div>
                              </Col>
                              <Col lg={2}>
                                <div className="input-select">
                                  <Form.Select aria-label="Default select example">
                                    <option> Category</option>
                                    <option value="1">1 Month</option>
                                    <option value="2">6 Month</option>
                                    <option value="3">1 year</option>
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <Table size="sm" className="table-cmn">
                            <thead>
                              <tr>
                                <th>
                                  <div className="user-checkbox">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    Date
                                  </div>
                                </th>

                                <th>Lead Overview</th>
                                <th>Location</th>
                                <th>Responses</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>
                                <td>
                                  <div className="debit">0/5</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/edit-icon.svg")
                                          .default
                                      }
                                    />
                                    <img
                                      src={
                                        require("../Assets/Images/options.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className="pagination-section">
                            <div className="showing-user">
                              <p>Showing 1 to 10 of 50 entries</p>
                            </div>
                            <div className="pagination-block">
                              <Pagination>
                                <Pagination.First />
                                <Pagination.Prev />
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Item>{2}</Pagination.Item>
                                <Pagination.Item active>{3}</Pagination.Item>
                                <Pagination.Item>{4}</Pagination.Item>
                                <Pagination.Item>{5}</Pagination.Item>
                                <Pagination.Next />
                                <Pagination.Last />
                              </Pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-5">
                      <p className="mb-1"></p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div className="favourite-electricians-cards mt-4">
                      <Row>
                        <Col lg={4}>
                          <div className="favourite-electricians-item">
                            <img
                              src={
                                require("../Assets/Images/my-profile.svg")
                                  .default
                              }
                            />
                            <h3>T.R.C Electicians</h3>
                            <p>Electrician</p>
                            <span>Regular</span>
                            <div class="comn-btn-set d-flex justify-content-center">
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <ellipse
                                    cx="6.00004"
                                    cy="4.66667"
                                    rx="2.66667"
                                    ry="2.66667"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></ellipse>
                                  <path
                                    d="M2 14V12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333C8.80609 10 10 11.1939 10 12.6667V14"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M10.6666 7.33333L12 8.66667L14.6666 6"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                Favourited
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="favourite-electricians-item">
                            <img
                              src={
                                require("../Assets/Images/my-profile.svg")
                                  .default
                              }
                            />
                            <h3>T.R.C Electicians</h3>
                            <p>Electrician</p>
                            <span>Regular</span>
                            <div class="comn-btn-set d-flex justify-content-center">
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <ellipse
                                    cx="6.00004"
                                    cy="4.66667"
                                    rx="2.66667"
                                    ry="2.66667"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></ellipse>
                                  <path
                                    d="M2 14V12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333C8.80609 10 10 11.1939 10 12.6667V14"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M10.6666 7.33333L12 8.66667L14.6666 6"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                Favourited
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="favourite-electricians-item">
                            <img
                              src={
                                require("../Assets/Images/my-profile.svg")
                                  .default
                              }
                            />
                            <h3>T.R.C Electicians</h3>
                            <p>Electrician</p>
                            <span>Regular</span>
                            <div class="comn-btn-set d-flex justify-content-center">
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <ellipse
                                    cx="6.00004"
                                    cy="4.66667"
                                    rx="2.66667"
                                    ry="2.66667"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></ellipse>
                                  <path
                                    d="M2 14V12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333C8.80609 10 10 11.1939 10 12.6667V14"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M10.6666 7.33333L12 8.66667L14.6666 6"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                Favourited
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </Layout>
  );
}
