import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

export default function ElectricianProfile() {
  return (
    <Layout>
      <Container fluid>
        <div className="cmn-detail-head-box mt-4">
          <div className="cmn-detail-top"></div>
          <div className="cmn-detail-bottom">
            <img src={require("../Assets/Images/my-profile.svg").default} />
            <div className="cmn-detail-data">
              <div className="">
                <h2>John Doe</h2>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="11"
                        r="3"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.657 16.657L13.414 20.9C12.6331 21.68 11.3679 21.68 10.587 20.9L6.343 16.657C3.21892 13.5327 3.21901 8.46747 6.34319 5.34334C9.46738 2.21921 14.5326 2.21921 17.6568 5.34334C20.781 8.46747 20.7811 13.5327 17.657 16.657V16.657Z"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Manchester
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        x="4"
                        y="5"
                        width="16"
                        height="16"
                        rx="2"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 3V7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 3V7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 11H20"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 15H12"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15V18"
                        stroke="#2F2B3D"
                        stroke-opacity="0.7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Joined November 2024
                  </li>
                </ul>
              </div>
              <div className="comn-btn-set">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <ellipse
                      cx="6.00004"
                      cy="4.66667"
                      rx="2.66667"
                      ry="2.66667"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 14V12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333C8.80609 10 10 11.1939 10 12.6667V14"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6666 7.33333L12 8.66667L14.6666 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Connected
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="mt-4">
        <Container fluid>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="comn-tabs-set">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          d="M2.59272 2.5927C1.1608 4.02462 2.90201 8.08743 6.48181 11.6672C10.0616 15.247 14.1244 16.9882 15.5563 15.5563C16.9883 14.1244 15.2471 10.0616 11.6673 6.48179C8.08746 2.90198 4.02465 1.16078 2.59272 2.5927"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.5565 2.59272C14.1245 1.1608 10.0617 2.90201 6.48193 6.48181C2.90213 10.0616 1.16092 14.1244 2.59285 15.5563C4.02477 16.9883 8.08758 15.2471 11.6674 11.6673C15.2472 8.08746 16.9884 4.02465 15.5565 2.59272"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.07454 9.07454V9.08371"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Profile Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="10.5"
                          y="3"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="3"
                          y="10.5"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="10.5"
                          y="10.5"
                          width="4.5"
                          height="4.5"
                          rx="1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Responses
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="fourth">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <rect
                          width="14.2857"
                          height="11.4286"
                          rx="3"
                          transform="matrix(-1 0 0 1 15.1428 4.57141)"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                        />
                        <path
                          d="M0.857107 7.96429V7.96429C5.54684 9.13672 10.4531 9.13672 15.1428 7.96429V7.96429"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M11.5714 4.57143V3C11.5714 1.89543 10.676 1 9.57141 1H6.42855C5.32399 1 4.42855 1.89543 4.42855 3V4.57143"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                        />
                      </svg>
                      Transactions & Credits
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Account Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sixth">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          d="M9.23836 9.25V11.5417"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <ellipse
                          cx="9.2382"
                          cy="8.33329"
                          rx="0.916667"
                          ry="0.916667"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.23824 1C11.3794 2.89436 14.174 3.88069 17.0299 3.75C17.87 6.60774 17.5152 9.68399 16.0467 12.2755C14.5782 14.867 12.1215 16.7521 9.23824 17.5C6.35499 16.7521 3.89829 14.867 2.42978 12.2755C0.961267 9.68399 0.606494 6.60774 1.44657 3.75C4.30247 3.88069 7.09706 2.89436 9.23824 1"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Verification
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="mt-4">
                      <Row>
                        <Col lg={4}>
                          <div className="commn-card-box">
                            <div className="overview-details">
                              <h4>ABOUT</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.0001 11V11.0092"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17.4819 4.51814C16.05 3.08622 11.9872 4.82742 8.40735 8.40723C4.82754 11.987 3.08634 16.0498 4.51826 17.4818C5.95018 18.9137 10.013 17.1725 13.5928 13.5927C17.1726 10.0129 18.9138 5.95006 17.4819 4.51814"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M4.51826 4.51824C3.08634 5.95016 4.82754 10.013 8.40735 13.5928C11.9872 17.1726 16.05 18.9138 17.4819 17.4819C18.9138 16.0499 17.1726 11.9871 13.5928 8.40732C10.013 4.82752 5.95018 3.08631 4.51826 4.51824"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Company:</p>
                                  <span>T.J.R Electricians LTD</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <circle
                                      cx="12"
                                      cy="7"
                                      r="4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6 21V19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Full Name:</p>
                                  <span>John Doe</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 12L10 17L20 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Subscription Level:</p>
                                  <span>Standard</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 5V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M19 5V14"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5 5.00003C6.94422 3.09431 10.0558 3.09431 12 5.00003C13.9442 6.90574 17.0558 6.90574 19 5.00003"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5 14C6.94422 12.0943 10.0558 12.0943 12 14C13.9442 15.9057 17.0558 15.9057 19 14"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>City:</p>
                                  <span>Manchester, UK</span>
                                </li>
                              </ul>
                              <h4>CONTACTS</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 20.1046 19.1046 21 18 21C9.92765 20.5094 3.49056 14.0724 3 6C3 4.89543 3.89543 4 5 4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 7C16.1046 7 17 7.89543 17 9"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 3C18.3137 3 21 5.68629 21 9"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Contact:</p>
                                  <span>+44 7477220000</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <rect
                                      x="3"
                                      y="5"
                                      width="18"
                                      height="14"
                                      rx="2"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 7L12 13L21 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Email:</p>
                                  <span>John.doe@example.com</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="commn-card-box">
                            <div className="activity-timeline-box">
                              <h2>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    x="3"
                                    y="12"
                                    width="6"
                                    height="8"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <rect
                                    x="9"
                                    y="8"
                                    width="6"
                                    height="12"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <rect
                                    x="15"
                                    y="4"
                                    width="6"
                                    height="16"
                                    rx="1"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4 20H18"
                                    stroke="#2F2B3D"
                                    stroke-opacity="0.7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Activity Timeline
                              </h2>
                              <ul>
                                <li>
                                  <div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#337FE0"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#337FE0"
                                      />
                                    </svg>

                                    <div>
                                      <h4>Responded to Lead</h4>
                                      <p>Call Out - Emergency Services</p>
                                    </div>
                                  </div>
                                  <span>12 min ago</span>
                                </li>
                                <li>
                                  <div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#28C76F"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#28C76F"
                                      />
                                    </svg>

                                    <div>
                                      <h4>Verified Phone Number</h4>
                                      <p>Verified by SMS </p>
                                    </div>
                                  </div>
                                  <span>12 min ago</span>
                                </li>
                                <li>
                                  <div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.16"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#00BAD1"
                                      />
                                      <circle
                                        cx="9"
                                        cy="9"
                                        r="6"
                                        fill="#00BAD1"
                                      />
                                    </svg>
                                    <div>
                                      <h4>Account Created</h4>
                                      <p>
                                        Registered to Spark Pro on Thursday 2nd
                                        November 2024 08:02
                                      </p>
                                    </div>
                                  </div>
                                  <span>12 min ago</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="commn-card-box mt-4">
                            <div className="overview-details">
                              <h4>OVERVIEW</h4>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 12L10 17L20 7"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Credits in Account:</p>
                                  <span>32</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <circle
                                      cx="9"
                                      cy="7"
                                      r="4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M21 20.9999V18.9999C20.9896 17.1845 19.7578 15.6037 18 15.1499"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Leads Responded To:</p>
                                  <span>156</span>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <circle
                                      cx="9"
                                      cy="7"
                                      r="4"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M21 20.9999V18.9999C20.9896 17.1845 19.7578 15.6037 18 15.1499"
                                      stroke="#2F2B3D"
                                      stroke-opacity="0.7"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <p>Total Spend</p>
                                  <span>£1,230</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="my-profile-page mt-4">
                      <div className="profile-top-box">
                        <h5 className="mb-4">Basics</h5>
                        <div className="photo-upload">
                          <div className="profile-picture">
                            <img
                              src={
                                require("../Assets/Images/my-profile.svg")
                                  .default
                              }
                            />
                          </div>
                          <div className="upload-box">
                            <div className="btn-up">
                              <input
                                type="file"
                                accept="image/*"
                                id="uploadPhoto"
                                class="file-input"
                              />
                              <label
                                for="uploadPhoto"
                                class="custom-file-upload"
                              >
                                Upload New Logo
                              </label>
                              <div className="reset-box">
                                <p>Delete</p>
                              </div>
                            </div>
                            <p className="image-disp">Allowed JPG or PNG</p>
                          </div>
                        </div>

                        <div className="profile-disp mt-5">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Company Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="T.J.R Electricians LTD"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Company Size</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="10-20 Employees"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Incorporation Date</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="01/01/2020"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        {/* <div className="btn-up mt-2">
                          <button type="submit">Save Changes</button>
                          <div className="reset-box">
                            <p>Cancel</p>
                          </div>
                        </div> */}
                      </div>

                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Address</h2>
                          <div className="profile-disp">
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="124 City Road"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="London"
                                />
                              </Form.Group>
                            </div>

                            <div className="form-set input-profile mt-2">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Post Code</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="EC1V 2NX"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Radius Covered</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="10 Miles"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Contact Info</h2>
                          <div className="profile-disp">
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="email@email.com"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="+442038806050"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <h2 className="mb-2">Opening Hours</h2>
                          <div className="profile-disp">
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Monday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>

                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Tuesday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>

                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Wednesday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Thursday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Friday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Saturday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-set input-profile">
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Sunday</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00am"
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="formBasicPassword"
                              >
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="08:00pm"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Portfolio</h2>
                          <div className="portfolio-in-electrician">
                            <Row>
                              <Col lg={4}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Abby Road</h5>
                                  <p>Commercial Project</p>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Abby Road</h5>
                                  <p>Commercial Project</p>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Abby Road</h5>
                                  <p>Commercial Project</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Accreditations</h2>
                          <div className="portfolio-in-electrician">
                            <Row>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Masters Degree</h5>
                                  <p>Huddersfield University</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Masters Degree</h5>
                                  <p>Huddersfield University</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>Masters Degree</h5>
                                  <p>Huddersfield University</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Employees</h2>
                          <div className="portfolio-in-electrician">
                            <Row>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box employees-box-circle">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>James Smith</h5>
                                  <p>Senior Electrician</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box employees-box-circle">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>James Smith</h5>
                                  <p>Senior Electrician</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="portfolio-electrician-box employees-box-circle">
                                  <img
                                    src={require("../Assets/Images/banner-sign.png")}
                                  />
                                  <h5>James Smith</h5>
                                  <p>Senior Electrician</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pb-5">
                      <p class="mb-1"></p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="filters mt-4">
                      <div className="inner-filter-field">
                        <div className="table-data-title border-none">
                          <div className="category-field">
                            <Row>
                              <Col lg={4}>
                                <div className="input-search">
                                  <Form>
                                    <Form.Group controlId="formBasicPassword">
                                      <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        className="w-100"
                                      />
                                    </Form.Group>
                                  </Form>
                                </div>
                              </Col>
                              <Col lg={2}>
                                <div className="input-select">
                                  <Form.Select aria-label="Default select example">
                                    <option> Date Range </option>
                                    <option value="1">1 Month</option>
                                    <option value="2">6 Month</option>
                                    <option value="3">1 year</option>
                                  </Form.Select>
                                </div>
                              </Col>
                              <Col lg={2}>
                                <div className="input-select">
                                  <Form.Select aria-label="Default select example">
                                    <option> Category</option>
                                    <option value="1">1 Month</option>
                                    <option value="2">6 Month</option>
                                    <option value="3">1 year</option>
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <Table size="sm" className="table-cmn">
                            <thead>
                              <tr>
                                <th>
                                  <div className="user-checkbox">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    Date
                                  </div>
                                </th>
                                <th>USer</th>
                                <th>Lead Overview</th>
                                <th>Location</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <div className="first-user">
                                    <div className="remember-check">
                                      <input
                                        type="checkbox"
                                        class="red"
                                        id="filled-in-box"
                                      />
                                    </div>
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>01/12/2024</p>
                                        <span>08:35am</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      <div className="user-id">
                                        <p>Call Out</p>
                                        <span>Emergency Services</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="debit">Manchester</div>
                                </td>

                                <td>
                                  <div className="action">
                                    <img
                                      src={
                                        require("../Assets/Images/view-icon.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className="pagination-section">
                            <div className="showing-user">
                              <p>Showing 1 to 10 of 50 entries</p>
                            </div>
                            <div className="pagination-block">
                              <Pagination>
                                <Pagination.First />
                                <Pagination.Prev />
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Item>{2}</Pagination.Item>
                                <Pagination.Item active>{3}</Pagination.Item>
                                <Pagination.Item>{4}</Pagination.Item>
                                <Pagination.Item>{5}</Pagination.Item>
                                <Pagination.Next />
                                <Pagination.Last />
                              </Pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-5">
                      <p className="mb-1"></p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div>
                      <div className="inner-page-title">
                        <h2 className="">Credits</h2>
                        <div className="comn-btn-set">
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8.00008 3.33301V12.6663"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.33325 8.00033H12.6666"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Add Credits
                          </button>
                        </div>
                      </div>
                      <div className="dashboard-items mt-3">
                        <Row>
                          <Col xxl={12} xl={12} lg={12}>
                            <div className="dashbox-inner-wrap ">
                              <Row>
                                <Col xxl={3} xl={3} lg={3}>
                                  <div className="dash-inner-boxes">
                                    <div className="session">
                                      <div className="left-session">
                                        <h2>£84,320</h2>
                                        <p>Credits Available</p>
                                      </div>
                                      <div className="user-icon-se">
                                        <img
                                          src={
                                            require("../Assets/Images/active-user.svg")
                                              .default
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col xxl={3} xl={3} lg={3}>
                                  <div className="dash-inner-boxes">
                                    <div className="session">
                                      <div className="left-session">
                                        <h2>414</h2>
                                        <p> Purchased</p>
                                      </div>
                                      <div className="user-icon-se">
                                        <img
                                          src={
                                            require("../Assets/Images/padi-user.svg")
                                              .default
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={3} xl={3} lg={3}>
                                  <div className="dash-inner-boxes">
                                    <div className="session">
                                      <div className="left-session">
                                        <h2>923</h2>
                                        <p>Spent</p>
                                      </div>
                                      <div className="user-icon-se">
                                        <img
                                          src={
                                            require("../Assets/Images/pending-user.svg")
                                              .default
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col xxl={3} xl={3} lg={3}>
                                  <div className="dash-inner-boxes">
                                    <div className="session">
                                      <div className="left-session">
                                        <h2>923</h2>
                                        <p>Paid</p>
                                      </div>
                                      <div className="user-icon-se">
                                        <img
                                          src={
                                            require("../Assets/Images/pending-user.svg")
                                              .default
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div>
                      <div className="inner-page-title">
                        <h2 className="">Transactions</h2>
                      </div>
                      <div className="filters">
                        <div className="inner-filter-field">
                          <div className="table-data-title">
                            <h2 className="filter">Filters</h2>
                            <div className="category-field">
                              <Row>
                                <Col lg={4}>
                                  <div className="input-select">
                                    <Form.Select aria-label="Default select example">
                                      <option>Invoice Status</option>
                                      <option value="1">1 Month</option>
                                      <option value="2">6 Month</option>
                                      <option value="3">1 year</option>
                                    </Form.Select>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="user-search">
                            <div className="drop-down">
                              <div className="select-box">
                                <Form.Select aria-label="Default select example">
                                  <option>+91</option>
                                  <option value="1">9</option>
                                </Form.Select>
                              </div>
                            </div>
                            <div className="search-tab">
                              <div className="input-search">
                                <Form>
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Control
                                      type="text"
                                      placeholder="Search Transactions"
                                    />
                                  </Form.Group>
                                </Form>
                              </div>
                              <div className="export-box">
                                <p>
                                  <span>
                                    <img
                                      src={
                                        require("../Assets/Images/upload.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Export
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="table-responsive">
                            <Table size="sm" className="table-cmn">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="user-checkbox">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      #
                                    </div>
                                  </th>
                                  <th>Date</th>
                                  <th>Electrician</th>
                                  <th>Payment Type</th>
                                  <th>Amount</th>
                                  <th>Quote Credits</th>
                                  <th>View Invoice</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <div className="first-user">
                                      <div className="remember-check">
                                        <input
                                          type="checkbox"
                                          class="red"
                                          id="filled-in-box"
                                        />
                                      </div>
                                      <div>#4910</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cont-num"> 22 Oct 2024</div>
                                  </td>
                                  <td>
                                    <div className="user-profile">
                                      <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      />
                                      <div className="user-id">
                                        <p>Jordan Stevenson</p>
                                        <span>jordan.stevenson</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="debit">Subscription</div>
                                  </td>
                                  <td>
                                    <div className="debit">£80.00</div>
                                  </td>

                                  <td>
                                    <div className="debit">+ 10</div>
                                  </td>
                                  <td>
                                    <div className="action">
                                      <img
                                        src={
                                          require("../Assets/Images/view-icon.svg")
                                            .default
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <div className="pagination-section">
                              <div className="showing-user">
                                <p>Showing 1 to 10 of 50 entries</p>
                              </div>
                              <div className="pagination-block">
                                <Pagination>
                                  <Pagination.First />
                                  <Pagination.Prev />
                                  <Pagination.Item>{1}</Pagination.Item>
                                  <Pagination.Item>{2}</Pagination.Item>
                                  <Pagination.Item active>{3}</Pagination.Item>
                                  <Pagination.Item>{4}</Pagination.Item>
                                  <Pagination.Item>{5}</Pagination.Item>
                                  <Pagination.Next />
                                  <Pagination.Last />
                                </Pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pb-5">
                        <p className="mb-1"></p>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="fifth">
                    <div className="my-profile-page mt-4">
                      <div className="profile-top-box">
                        <h5 className="mb-4">Personal Info</h5>

                        <div className="profile-disp mt-0">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="type"
                                placeholder="John"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="type"
                                placeholder="Doe"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" placeholder="john.doe@example.com" />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="+447477202151"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="btn-up mt-2">
                          <button type="submit">Save Changes</button>
                          <div className="reset-box">
                            <p>Cancel</p>
                          </div>
                        </div>
                      </div>

                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-4">Account Actions</h2>
                          <div className="d-flex flex-direction-column gap-3">
                            <button className="cmmn-white-btn">
                              Reset Password
                            </button>
                            <button className="cmmn-white-btn">
                              Suspend Account
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="delete-account mt-4">
                        <div className="delete-heading-text  ">
                          <h1>Delete Account</h1>
                        </div>
                        <div className="delete-disp">
                          <p>Are you sure you want to delete this account?</p>
                          <span>
                            Once you delete this account, there is no going
                            back. Please be certain.
                          </span>
                        </div>
                        <div className="delete-check-box">
                          <div className="remember-check check-box-over">
                            <input
                              type="checkbox"
                              class="red"
                              id="filled-in-box"
                            />
                            <Form.Label>
                              I confirm my account deactivation
                            </Form.Label>
                          </div>
                        </div>
                        <div className="btn-delete-account">
                          <button>Deactivate Account</button>
                        </div>
                      </div>
                    </div>
                    <div class="pb-5">
                      <p class="mb-1"></p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <div className="my-profile-page mt-4">
                      <div className="profile-top-box">
                        <h5 className="mb-4">Verification Details</h5>

                        <div className="profile-disp mt-0">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Company Registration Number
                              </Form.Label>
                              <Form.Control type="type" placeholder="0123415" />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>
                                Government Body Registered With
                              </Form.Label>
                              <Form.Control
                                type="type"
                                placeholder="Lorem Ipsum "
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Registration Number</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="098765"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Insurance Policy Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="0123456"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="view-document-upld">
                          <img
                            src={require("../Assets/Images/banner-sign.png")}
                          />
                          <button className="cmmn-white-btn">
                            View Document
                          </button>
                        </div>
                      </div>

                      <div className="profile-top-box mt-4">
                        <div className="heading-profile">
                          <h2 className="mb-2">Verification Status</h2>
                          <div className="verification-status">
                            <Form.Select aria-label="Default select example">
                              <option>Pending</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pb-5">
                      <p class="mb-1"></p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </Layout>
  );
}
